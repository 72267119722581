<template>
  <div class="first-forum-detail">
    <br>
    <BTitle :text="forumInfo[thread].title"></BTitle>
    <div class="first-forum-content center">
      <!-- <div v-if="forumInfo[thread].desc" class="desc" v-html="forumInfo[thread].desc"></div> -->
      <!-- <br> -->
      <div class="live">
        <video class="video" controls :poster="forumInfo[thread].videoPoster? forumInfo[thread].videoPoster : '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E8%A7%86%E9%A2%91/2018/videoPoster.jpg'" :src="forumInfo[thread].video"></video>
      </div>
      <br>
      <DTitle  v-if="forumInfo[thread].guests && forumInfo[thread].guests.length > 0" style="margin:0 8px;" text="演讲嘉宾" :tip="thread!=0? '以下嘉宾按论坛演讲出场顺序排列。':''"/>
      <br>
      <div v-if="forumInfo[thread].listType">
        <div class="list" v-for="(item, index) in forumInfo[thread].guests" :key="index">
          <b>{{ item[0] }}</b>{{ item[1] }}
        </div>
      </div>
      <div v-else class="flex guests-wrap">
        <div v-for="(item, index) in forumInfo[thread].guests" :key="index" class="tc guest">
          <div class="img">
            <img :src="forumInfo[thread].imgBase+(item[0].replace(/\s+/g,'')) + '.jpg' + '?x-oss-process=image/resize,m_fill,h_460,w_396'">
            <div class="name">
              <p v-html="item[1]"></p>
            </div>
          </div>
          <h3>{{item[0]}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thread: this.$route.query.thread,
      forumInfo: [
        {
          title: '开幕式暨主旨论坛',
          guests: [
            ['柯吉欣','浙江省人民政府副省长',''],
            ['李天碧','交通运输部总工程师兼水运局局长',''],
            ['李术才','山东大学校长、中国工程院院士',''],
            ['翁孟勇','中国公路学会理事长、交通运输部原党组副书记、副部长',''],
            ['史坚忠','上海市航空学会理事长，中国商飞专家组组长、原副总经理',''],
            ['黄奇帆','研究员、重庆市原市长',''],
            ['施清宏','省人民政府副秘书长',''],
            ['蔡洪','省交通运输厅厅长',''],
            ['章春华','省发展和改革委副主任',''],
            ['王忠民','省经济和信息化厅党组副书记、副厅长',''],
            ['王敏','杭州市委常委、萧山区委书记',''],
            ['邵宏','省交通运输厅党组副书记、副厅长',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2023/01%E3%80%81%E7%AC%AC%E4%BA%94%E5%B1%8A%E6%B5%99%E6%B1%9F%E5%9B%BD%E9%99%85%E6%99%BA%E6%85%A7%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8D%9A%E8%A7%88%E4%BC%9A%E5%BC%80%E5%B9%95%E5%BC%8F%E6%9A%A8%E4%B8%BB%E6%97%A8%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/01、第五届浙江国际智慧交通产业博览会开幕式暨主旨论坛/01、主K-第五届浙江国际智慧交通产业博览会.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/01、第五届浙江国际智慧交通产业博览会开幕式暨主旨论坛/',
        },
        {
          title: '“践行可持续发展 迈向未来绿色交通”高端对话',
          listType: true,
          guests: [
            ['郑怀宇','中国国际可持续交通创新和知识中心副主任'],
            ['李天碧','交通运输部总工程师、水运局局长'],
            ['刘世宇','中国能源建设股份有限公司电力规划总院副院长（副司级）'],
            ['龚曦','空中客车中国公司高管'],
            ['张峻屹','东南大学长江学者、外籍院士（日本工程院）'],
            ['毕俊杰','国家发改委中宏基础设施基金执行董事']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/02、“践行可持续发展 迈向未来绿色交通”高端对话.mp4',
          videoPoster: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/02、“践行可持续发展 迈向未来绿色交通”高端对话/01、主K.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/01、第五届浙江国际智慧交通产业博览会开幕式暨主旨论坛/',
        },
        {
          title: '浙江未来交通科创中心第三届科技成果推介会-主旨论坛',
          listType: true,
          guests: [
            ['李术才','中国工程院院士',''],
            ['吴德兴','浙江数智交院科技股份有限公司董事长，全国勘察设计大师',''],
            ['姚建华','浙江工业大学机械工程学院院长，浙江省特级专家',''],
            ['张姿','杭州海康威视数字技术股份有限公司交通行业总监',''],
            ['丁成远','中兴通讯股份有限公司解决方案总监',''],
            ['方奕','浙江海港大宗商品交易中心有限公司董事长总经理',''],
            ['迟凤霞','浙江省交通运输科学研究院党委委员、浙江省道桥检测与养护技术研究重点实验室主任',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/03、浙江未来交通科创中心第三届科技成果推介会主旨论坛.mp4',
          videoPoster: '///itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/717008978543216-16X9.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/03、浙江未来交通科创中心第三届科技成果推介会主旨论坛/',
        },
        {
          title: '航运服务创新发展论坛',
          guests: [
            ['王竹凌','浙江省港航管理中心党委副书记、副主任',''],
            ['夏炳荣','浙江省交通运输厅一级巡视员',''],
            ['叶志航','浙江省海港集团、宁波舟山港集团党委委员、副总经理',''],
            ['贾大山','亚太港口服务组织（APSN）前秘书长',''],
            ['吴国生','中国气象局气象导航中心技术总监',''],
            ['杜昱','德路里航运咨询中国区总经理',''],
            ['黄晨亮','中国海事仲裁委员会（浙江）自由贸易试验区仲裁中心副秘书长',''],
            ['王琪俊','浙江船舶交易市场有限公司首席分析师',''],
            ['方舟','浙江数智交院科技股份有限公司高级工程师',''],
            ['孙颖','波罗的海交易所中国区代表',''],
            ['王涌','浙江新世纪期货有限公司党委委员、首席风险官',''],
            ['高洋江','宁波市鄞州区物贸联合会会长',''],
            ['张新','中哲物产集团有限公司研究员',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/07、航运服务创新发展论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/317008977421355-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/07、航运服务创新发展论坛/',
        },
        {
          title: '民航智慧机场创新发展论坛',
          guests: [
            ['卢永平','浙江省机场集团总经理',''],
            ['刘一','浙江省交通运输厅副厅长',''],
            ['黄飙','杭州萧山国际机场智慧机场建设办公室主任',''],
            ['罗喜伶','民航管理干部学院大数据与人工智能系主任、教授',''],
            ['浦黎','珠海机场集团技术顾问、原深圳机场集团数字化管理中心总经理',''],
            ['钮悦','北京航空航天大学杭州创新研究院教授',''],
            ['毛健','中国民航信息网络股份有限公司机场事业部产品总监',''],
            ['张贵联','国际航协北亚区货运负责人','']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/05、民航智慧机场创新发展论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/117008977888286-%E6%B0%91%E8%88%AA%E6%9C%BA%E5%9C%BA%E7%BD%91%E7%AB%99%E5%9B%BE-01.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/05、民航智慧机场创新发展论坛/',
        },
        {
          title: '轨道交通发展论坛',
          guests: [
            ['叶玉玲','同济大学教授、博士生导师、上海交通委科学技术委员会委员',''],
            ['李平','中国铁道科学研究院集团有限公司首席研究员',''],
            ['杨彦生','中铁上海设计院集团有限公司副总工程师',''],
            ['刘震','江苏省铁路集团铁路运营公司副总经理',''],
            ['苏钊颐','国家发改委城市轨道交通系统安全与运维保障国家工程研究中心产业化经理',''],
            ['刘韶庆','国家高速列车技术创新中心副主任，教授级高工',''],
            ['倪一清','香港理工大学国家轨道交通电气化与自动化工程技术研究中心香港分中心主任',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/06、轨道交通发展论坛 .mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008977177330-16X9.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/06、轨道交通发展论坛/',
        },
        {
          title: '第一届绿色物流与供应链发展大会开幕式暨主论坛',
          guests: [
            ['任豪祥','中国物流与采购联合会副会长/中国物流学会会长',''],
            ['刘然','中国物流与采购联合会绿色物流分会秘书长',''],
            ['恽绵','中国物流与采购联合会绿色物流分会专家委员会副主任',''],
            ['吕建中','全球报告倡议组织（GRI）董事',''],
            ['高翔','中国外运股份有限公司副总经理/首席数字官',''],
            ['晁德文','伊利集团副总裁',''],
            ['文海','壳牌（上海）技术有限公司总经理/壳牌亚太区润滑油研发总经理',''],
            ['桑田','惠州亿纬锂能股份有限公司副总裁',''],
            ['王毅','三一集团总裁助理 /三一重卡电动化公司总经理',''],
            ['朱桢','联合利华中国区客户运作总监',''],
            ['杨绍泳','宝供物流企业集团有限公司总裁',''],
            ['赵洁玉','中国物流与采购联合会绿色物流分会副秘书长',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/04、第一届绿色物流与供应链发展大会开幕式暨主论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008977027261-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/04、第一届绿色物流与供应链发展大会开幕式暨主论坛/',
        },
        {
          title: '第四届未来交通创新发展论坛',
          guests: [
            ['朱政','浙江大学智能交通研究所百人计划研究员，土木工程学系系主任助理',''],
            ['曲小波','欧洲科学院院士、长江学者讲席教授',''],
            ['张磊','阿里云副总裁、交通物流行业总经理、清华大学杰出特聘教授',''],
            ['李萌','清华大学教授、清华大学-奔驰可持续交通发展研究院院长',''],
            ['邱志军','华砺智行（武汉）科技有限公司董事长、加拿大卡尔格里大学正教授',''],
            ['张勤','绍兴市交通投资集团有限公司数改办主任 ',''],
            ['崔真源','八维通科技有限公司首席科学家，加州伯克利大学计算机教授，国际BIM协会专家顾问',''],
            ['张越梅','龙兴航空电子有限公司副总裁、南京航空航天大学博士生导师',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/08、第四届未来交通创新发展论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/417008976677800-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/08、第四届未来交通创新发展论坛/',
        },
        {
          title: '浙江未来交通科创中心第三届科技成果推介会-成果推介活动',
          guests: [
            ['唐锡军', '浙江省交通运输科学研究院党委书记、院长，浙江公路水运工程咨询集团有限公司董事长'],
            ['刘洪亮', '山东大学-浙高建公司 隧道工程智能建造研究院'],
            ['廖军洪', '交通运输部公路科学研究院'],
            ['张学谦', '浙江大华技术股份有限公司（物联网技术应用交通运输行业研发中心）'],
            ['刘燕燕', '浙江交工集团股份有限公司（公路数智养护浙江省工程研究中心）'],
            ['程建旗', '浙江数智交院科技股份有限公司（桥梁隧道工业化浙江省工程研究中心）'],
            ['王强', '浙江省交通运输科学研究院（公路桥隧智能运维技术浙江省工程研究中心）'],
            ['徐涛', '浙江特勤卫星导航科技有限公司'],
            ['林道锦', '浙江嘉绍跨江大桥投资发展有限公司'],
            ['奚晨晨', '浙江省交通运输科学研究院（新一代人工智能技术交通运输行业研发中心）'],
            ['刘松荣', '浙江交投高速公路运营管理有限公司（高速公路隧道数智安全技术浙江省工程研究中心）'],
            ['周建英', '中电科（宁波）海洋电子研究院有限公司'],
            ['韦征', '浙江省交通工程管理中心'],
            ['陈荣伟', '浙江公路水运工程咨询集团有限公司'],
            ['王加义', '浙江高信技术股份有限公司'],
            ['刘丹丹', '浙江众合科技股份有限公司'],
            ['俞毅', '交通运输部东海航海保障中心'],
            ['杨凯', '浙江数智交院科技股份有限公司']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/09、浙江未来交通科创中心第三届科技成果推介会成果推介活动.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/317008980524474-1.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/09、浙江未来交通科创中心第三届科技成果推介会成果推介活动/',
        },
        {
          title: '“双碳引领，赋能绿色供应链未来”分论坛',
          guests: [
            ['恽绵','中国物流与采购联合会绿色物流分会专家委员会副主任',''],
            ['房伟权','世界资源研究所亚太GHGP负责人',''],
            ['宾晖','上海环境能源交易所副总经理',''],
            ['陈月华','隆基绿能中国政企地区部副总裁',''],
            ['季维东','联想集团联晟智达CTO/联想中国区物流总监',''],
            ['修平','阿里云智能集团物流行业总架构师',''],
            ['于嘉兴','妙盈科技可持续发展业务总监',''],
            ['刘哲','中国物流与采购联合会绿色物流分会评估策划部主任',''],
            ['潘永刚','罗戈研究院院长',''],
            ['李灏源','中国外运股份有限公司创新研发部（可持续发展部）总经理',''],
            ['孟毅','伊利集团低碳发展总监',''],
            ['林力','欧治云商碳中和办公室部门总经理',''],
            ['王波勇','智慧货运中心中国区主任',''],
            ['张廷','中汽碳（北京）数字技术中心有限公司高级主管',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/10、“双碳引领，赋能绿色供应链未来”分论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008976423550-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/10、“双碳引领，赋能绿色供应链未来”分论坛/',
        },
        {
          title: '“绿色循环，驱动物流+制造深度融合”分论坛',
          guests: [
            ['约瑟夫萨基斯', '伍斯特理工学院商学院教授'],
            ['赵栋晨','百威中国亚太物流运输经理',''],
            ['孔庆峰','满帮集团公共事务部总经理/公共政策研究中心主任',''],
            ['庄建林','中集运载科技有限公司副总经理',''],
            ['张隽','中国质量认证中心碳中和及零碳评价业务负责人',''],
            ['林昱廷','华为技术有限公司物流装备技术总监',''],
            ['鲁亚霜','北京绿色交易所绿色发展中心副主任',''],
            ['曹惠蕾','中国物流与采购联合会绿色物流分会国际合作部主任',''],
            ['周志成','中国物流与采购联合会研究室主任/公路货运分会秘书长/中国物流学会副秘书长',''],
            ['郝皓','上海市逆向物流与供应链协同创新中心主任/上海第二工业大学教授',''],
            ['陈颢','浙江省烟草专卖局物流管理处处长',''],
            ['苏猛','东方驿站物流科技(深圳)有限公司董事/总经理',''],
            ['汤鲁飞','浙江汤氏供应链管理有限公司董事长',''],
            ['池洁','路凯(大中华)控股有限公司助理总裁',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2023/11%E3%80%81%E2%80%9D%E7%BB%BF%E8%89%B2%E5%BE%AA%E7%8E%AF%EF%BC%8C%E9%A9%B1%E5%8A%A8%E7%89%A9%E6%B5%81%2B%E5%88%B6%E9%80%A0%E6%B7%B1%E5%BA%A6%E8%9E%8D%E5%90%88%E2%80%9D%E5%88%86%E8%AE%BA%E5%9D%9B.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008976154163-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E9%A6%96%E5%B1%8A%E5%9B%9E%E9%A1%BE/2023/3%E3%80%81%E5%BC%80%E5%B9%95%E5%BC%8F%E5%8F%8A%E5%88%86%E8%AE%BA%E5%9D%9B%E5%98%89%E5%AE%BE%E7%85%A7%E7%89%87%E5%92%8C%E4%BF%A1%E6%81%AF%E8%A1%A8/11%E3%80%81%E2%80%9C%E7%BB%BF%E8%89%B2%E5%BE%AA%E7%8E%AF%EF%BC%8C%E9%A9%B1%E5%8A%A8%E7%89%A9%E6%B5%81%2B%E5%88%B6%E9%80%A0%E6%B7%B1%E5%BA%A6%E8%9E%8D%E5%90%88%E2%80%9D%E5%88%86%E8%AE%BA%E5%9D%9B/',
        },
        {
          title: '“标准先行，助力行业高质量发展”分论坛',
          guests: [
            ['章晶','通标标准技术服务有限公司（SGS）北区销售总监',''],
            ['李修琳','浙江工商大学物流管理与工程系副主任',''],
            ['徐秉声','中国标准化研究院资环分院副研究员/全国环境管理标准化技术委员会秘书长',''],
            ['金蕾','中国物流与采购联合会标准工作部副主任',''],
            ['赵洁玉','中国物流与采购联合会绿色物流分会副秘书长',''],
            ['侯海云','鞍山钢铁集团有限公司副总工程师',''],
            ['李健','德勤气候变化与可持续发展事业群总监',''],
            ['张冉','亿海蓝(北京)数据技术股份公司副总裁',''],
            ['王明媛','中理检验有限公司双碳项目负责人',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/12、标准先行，助力行业高质量发展”分论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/917008975506074-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/12、“标准先行，助力行业高质量发展”分论坛/',
        },
        {
          title: '“创新驱动，绿色供应链物流国际化科技创新”分论坛',
          guests: [
            ['王灿','璞跃中国企业创新总监',''],
            ['刘佳诺','璞跃中国副总裁',''],
            ['杨钧','璞跃中国供应链物投研负责人',''],
            ['张剑锋','普洛斯研究院科技创新副总裁',''],
            ['陶均','松下真空节能新材料（重庆）有限公司技术部部长',''],
            ['胡启凡','观碳智能CEO/中国节能协会碳中和专委会副主任',''],
            ['高乙','乐橘集团副总经理/集团党委书记',''],
            ['邢梁立博','星猿哲首席商务',''],
            ['缪钧玮','面壁智能商业化副总裁',''],
            ['鹤田彬','Integral行业总监',''],
            ['朱文花','奇点临近行业拓展总监',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/13、创新驱动，绿色供应链物流国际化科技创新”分论坛 .mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/017008975696081-%E4%BA%91%E7%9B%B8%E5%86%8C%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE-1500x844.png',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/13、“创新驱动，绿色供应链物流国际化科技创新”分论坛/',
        },
        {
          title: '交通智能建养与低碳发展国际科技合作论坛',
          guests: [
            ['Luc.R.Taerwe','比利时皇家科学与艺术院院士,比利时根特大学名誉教授',''],
            ['陈玉森','荷兰国家应用科学院资深研究员、浙江交通集团首席科学家',''],
            ['Joan.R.Casas','西班牙加泰罗尼亚理工大学桥梁工程教授、国际桥梁安全与维护协会秘书长',''],
            ['葛拥军','浙江省交通运输科学研究院科研管理部主任，浙江省公路智能建养与低碳交通国际科技合作基地副主任',''],
            ['薛露露','世界资源研究所中国交通项目总监',''],
            ['孔庆林','浙江交工国际工程有限公司党委副书记、纪委书记、工会主席、首席安全官',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/14、交通智能建养与低碳发展国际科技合作论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/617008977668436-1.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/14、交通智能建养与低碳发展国际科技合作论坛/',
        },
        {
          title: '第四届综合交通创新创业大赛总决赛暨颁奖仪式',
          guests: [
            ['张婷','中交投资有限公司副总经理、产业研发中心总经理，博士，高级工程师',''],
            ['胡奕军','浙江省交通运输厅智慧交通处处长，高级经济师',''],
            ['钱国恩','杭州市萧山区科学技术局副局长',''],
            ['赵永国','国家“万人计划”科技创新领军人才 ，正高级工程师',''],
            ['金盛','浙江大学工程师学院副院长、智能交通研究所副所长、博士、教授',''],
            ['戴红良','浙江省交通运输科学研究院智能交通研究所主任工程师',''],
            ['王晓利','中国技术创业协会孵化分会秘书长',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/15、第四届综合交通创新创业大赛总决赛暨颁奖仪式.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/117008976846148-16X9.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/15、第四届综合交通创新创业大赛总决赛暨颁奖仪式/',
        },
        {
          title: '中国（浙江）车联网产业发展创新论坛',
          guests: [
            ['殷承良','上海交通大学教授、智能网联新能源汽车领域首席研究员',''],
            ['章洪春','莫干山高新区管委会副主任',''],
            ['康陈','中国信通院标准所车联网部高级业务主管',''],
            ['朱福根','浙江交通职业技术学院汽车学院党总支书记、院长',''],
            ['肖禹','阿里云交通汽车行业研发总经理',''],
            ['周俊杰','浙江中控信息产业股份有限公司党委书记、副总裁',''],
            ['刘云鹏','浙江海康智联科技有限公司副总经理',''],
            ['朱克玉','百度智能云浙江解决方案总监、绍兴市城投阿波罗科技有限公司总经理',''],
            ['潘国强','浙江交通职业技术学院党委委员、浙江省数智交通产业联盟副秘书长',''],
            ['王晓鸣','中移（上海）产业研究院智慧交通解决方案总监','']
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/16、中国（浙江）车联网产业发展创新论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/917008978747907-16X9.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/16、中国（浙江）车联网产业发展创新论坛/',
        },
        {
          title: '绿色物流大讲堂暨“双碳”人才培养论坛',
          guests: [
            ['张晓东','北京交通大学交通运输学院物流工程系主任',''],
            ['刘伟华','天津大学运营与供应链管理系主任',''],
            ['马光宇','鞍钢集团钢铁研究院环境与资源研究所所长',''],
            ['李修琳','浙江工商大学物流管理与工程系副主任',''],
            ['陆静','通标标准技术服务有限公司(SGS)培训中心中国区市场经理',''],
            ['金玉然','辽宁科技大学低碳经济与智慧商业研究所所长',''],
            ['李俊峰','中国物流与采购联合会教育培训部副主任',''],
            ['傅培华','浙江工商大学现代物流与供应链研究中心主任',''],
            ['姜金德','南京晓庄学院教务处副处长',''],
            ['赵川','北京工商大学商学院副院长',''],
            ['李犇','中外运物流有限公司新零售事业部总经理/中外运物流华东有限公司总经理',''],
            ['鲁亚霜','北京绿色交易所绿色发展中心副主任',''],
          ],
          video: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/17、绿色物流大讲堂暨“双碳”人才培养论坛.mp4',
          videoPoster: '//itie-pc.oss-cn-hangzhou.aliyuncs.com/banner/forum/617008981656890-%E7%9B%B4%E6%92%AD%E5%B0%81%E9%9D%A2%E5%9B%BE.jpg',
          imgBase: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2023/3、开幕式及分论坛嘉宾照片和信息表/17、绿色物流大讲堂暨“双碳”人才培养论坛/',
        }
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first-forum-content {
  min-height: calc(100vh - 565px);
  padding-bottom: 30px;
  .desc {
    color: #666;
    font-size: 16px;
    text-indent: 32px;
    line-height: 25px;
  }
  .live {
    width: 1000px;
    margin: 0 auto;
    .video {
      margin: 20px auto;
      width: 1000px;
      height: 564px;
      border-radius: 5px;
      background: #342DB0;
      box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
    }
  }
  .list {
    padding: 8px 16px;
    margin: 8px;
    border: 1px solid #DDD;
    font-size: 14px;
    b {
      display: inline-block;
      min-width: 50px;
    }
  }
  .guests-wrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    .guest {
      margin: 8px;
      height: 285px;
      width: 200px;
      border: 1px solid #DDD;
      border-radius: 3px;
      .img {
        height: 230px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #DDD;
        img {
          max-height: 230px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .name {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -115px;
          height: 115px;
          background: rgba(0, 0, 0, 0.4);
          transition: all ease-out 0.5s;
          padding: 10px;
          color: #FFF;
          text-align: left;
          font-weight: bold;
          p {
            font-size: 14px;
            // vertical-align: middle;
          }
        }
      }
      h3 {
        font-size: 16px;
        color: #333;
        margin-top: 10px;
        line-height: 30px;
      }
    }
    .guest:hover {
      .name {
        bottom: 0px;
      }
    }
    .list {
      height: 185px;
    }
  }
}
</style>
